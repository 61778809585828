<template>
  <div class="modal fade" :id="modalPayment" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitle">Link Telekonsul Dokter</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"  @click="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
        <a class="text-break" :href="linkTelekonsul" target="_blank">
            {{linkTelekonsul}}
        </a>
         <input type="hidden" id="linkUrl" :value="linkTelekonsul">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" @click="copyCommand('linkUrl')">Copy</button>
        <button type="button" class="btn btn-primary" @click="openTelmed(linkTelekonsul)">Join Telekonsul</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal"  @click="close()">Tutup</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { RepositoryFactory } from "./../../../../repositories/RepositoryFactory";
const TelekonsulRepository = RepositoryFactory.get("teleconsultResource");


export default {
    data() {
        return {
            linkUrl: ''
        }
    },
    props: {
        modalPayment: { type: String, default: "modalPayment" },
        value: Object
    },
    computed: {
        ...mapState('DoctorCallerStore', {
          linkTelekonsul: state => state.linkTelekonsul,
      }),
    },
    methods:{
        ...mapMutations({
            setShowModalLinkTelekonsul: "DoctorCallerStore/SET_SHOW_LINK_TELEKONSUL"
        }),
      openTelmed(link){
        window.open(link)
      },
       close() {
            $("#" + this.modalPayment).modal("hide");
            this.setShowModalLinkTelekonsul(false);
        },
        copyCommand(element_id){
            let testingCodeToCopy = document.querySelector('#linkUrl')
            testingCodeToCopy.setAttribute('type', 'text')
            testingCodeToCopy.select()
            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                 toastr.success('Berhasil disalin')
            } catch (err) {
                 toastr.error('Gagal disalin')
            }

            /* unselect the range */
            testingCodeToCopy.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        },
    },
    async mounted () {
      $("#" + this.modalPayment).modal();
      this.wizard = new KTWizard("kt_wizard_v1", {
          startStep: 1,
      });
    },
}
</script>

<style>

</style>