<template>
  <div class="modal fade" :id="modalPayment" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitle">Link Telekonsul</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"  @click="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
        <a class="text-break" :href="linkUrl" target="_blank">
            {{linkUrl}}
        </a>
         <input type="hidden" id="linkUrl" :value="linkUrl">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" @click="copyCommand('linkUrl')">Copy</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal"  @click="close()">Tutup</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { RepositoryFactory } from "./../../../../repositories/RepositoryFactory";
const TelekonsulRepository = RepositoryFactory.get("teleconsultResource");


export default {
    data() {
        return {
            linkUrl: ''
        }
    },
    props: {
        modalPayment: { type: String, default: "modalPayment" },
        value: Object
    },
    computed: {
        ...mapState('DayCalendarStore', {
          showModalTelekonsul: state => state.showModalTelekonsul,
          event: state => state.selectedEvent,
      }),
    },
    methods:{
        ...mapMutations({
                setShowModalLinktelekonsul:'DayCalendarStore/SET_SHOW_MODAL_LINKTELEKONSUL',
        }),
       close() {
            $("#" + this.modalPayment).modal("hide");
            this.setShowModalLinktelekonsul(false);
        },
        async convertURL() {
            const event = this.event
            const queueDevice = JSON.parse(sessionStorage.getItem('queueDevice'))
            // let tenant = JSON.parse(localStorage.getItem("selectedTent"))
            let link = await TelekonsulRepository.generateLink()
            var baseUrl = link.data.split('?');
            let obj = Object.fromEntries(new URLSearchParams(baseUrl[1]));
            obj.linkId = event.reservasi.teleconsultPayment.linkId
            obj.password = event.reservasi.teleconsultPayment.password
            obj.queueDeviceUniqueId = queueDevice.uniqueId
            obj.tenantId = window.tenantActive

            let str = this.encodeUriParams(obj)
            let tujuan = `${baseUrl[0]}?${str}`
            return tujuan
        },
        copyCommand(element_id){
            let testingCodeToCopy = document.querySelector('#linkUrl')
            testingCodeToCopy.setAttribute('type', 'text')
            testingCodeToCopy.select()
            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                 toastr.success('Berhasil disalin')
            } catch (err) {
                 toastr.error('Gagal disalin')
            }

            /* unselect the range */
            testingCodeToCopy.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        },
        encodeUriParams(obj) {
                var str = [];
                for (var p in obj) {
                    if (obj.hasOwnProperty(p)) {
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                }
                return str.join("&");
            },
    },
    async mounted () {
      $("#" + this.modalPayment).modal();
      this.wizard = new KTWizard("kt_wizard_v1", {
          startStep: 1,
      });
      this.linkUrl = await this.convertURL()
    },
}
</script>

<style>

</style>